import axios from "axios"
import { useAuthentication } from "@today/auth"
import useSWR, { Key, SWRConfiguration, SWRResponse } from "swr"
import useSWRInfinite, {
  SWRInfiniteConfiguration,
  SWRInfiniteResponse,
} from "swr/infinite"

export const SWR_AUTH_TOKEN_HEADER = "x-today-openapi-token"

export function useSWRWithAuth<Data, Error = any>(
  key: Key,
  config?: SWRConfiguration
): SWRResponse<Data, Error> {
  const { accessToken } = useAuthentication()
  return useSWR([key, accessToken], {
    ...(config ?? {}),
    fetcher: (url: string, token: string) =>
      axios
        .get(url, { headers: { [SWR_AUTH_TOKEN_HEADER]: token } })
        .then((res) => res.data),
  })
}

export function useSWRInfiniteWithAuth<Data, Error = any>(
  getKey: (index: number, previousPageData: Data) => Key,
  config?: SWRInfiniteConfiguration
): SWRInfiniteResponse<Data, Error> {
  const { accessToken } = useAuthentication()
  return useSWRInfinite(
    (index: number, previousPageData: Data) => {
      console.log(index, previousPageData)
      console.log(getKey(index, previousPageData))
      return [getKey(index, previousPageData), accessToken]
    },
    {
      ...(config ?? {}),
      fetcher: (url: string, token: string) =>
        axios
          .get(url, { headers: { [SWR_AUTH_TOKEN_HEADER]: token } })
          .then((res) => {
            console.log(res)
            return res.data
          }),
    }
  )
}
