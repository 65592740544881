import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useAuthentication } from "../../keycloak"
import { useRouter } from "next/router"
import { toaster } from "baseui/toast"

interface AuthTimer {
  remainingSeconds: number
  touch(): void
}

const AuthTimerContext = createContext<AuthTimer>({
  remainingSeconds: 0,
  touch: () => {},
})

type AuthTimerProviderProps = {
  seconds: number
}

export function AuthTimerProvider({
  seconds,
  children,
}: PropsWithChildren<AuthTimerProviderProps>) {
  const initialTime = useMemo(() => new Date().getTime(), [])
  const [limitTime, setLimitTime] = useState(initialTime + seconds)
  const { logout } = useAuthentication()
  const router = useRouter()

  const touch = useCallback(() => {
    setLimitTime(new Date().getTime() + seconds * 1000)
  }, [seconds])

  const [remainingSeconds, setRemainingSeconds] = useState(seconds)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingSeconds(
        Math.max(0, Math.floor((limitTime - new Date().getTime()) / 1000))
      )
    }, 100)
    return () => {
      clearInterval(intervalId)
    }
  }, [limitTime])

  useEffect(() => {
    touch()
  }, [router.asPath])

  useEffect(() => {
    if (remainingSeconds <= 0) {
      logout()
    }
    if (remainingSeconds === 30) {
      toaster.negative(<>자동 로그아웃까지 30초 남았습니다.</>)
    }
    if (remainingSeconds === 60) {
      toaster.negative(<>자동 로그아웃까지 1분 남았습니다.</>)
    }
    if (remainingSeconds === 120) {
      toaster.negative(<>자동 로그아웃까지 2분 남았습니다.</>)
    }
    if (remainingSeconds === 180) {
      toaster.negative(<>자동 로그아웃까지 3분 남았습니다.</>)
    }
  }, [remainingSeconds, logout])

  return (
    <AuthTimerContext.Provider value={{ remainingSeconds, touch }}>
      {children}
    </AuthTimerContext.Provider>
  )
}

export function useAuthTimer() {
  return useContext(AuthTimerContext)
}
