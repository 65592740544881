import { APIProvider } from "@today/api"
import { AuthenticationProvider, AuthInitialProps, withAuth } from "@today/auth"
import axios from "axios"
import { BaseProvider, LightTheme, LocaleProvider } from "baseui"
import { ToasterContainer } from "baseui/toast"
import dayjs from "dayjs"
import "dayjs/locale/ko"
import isoWeek from "dayjs/plugin/isoWeek"
import localizedFormat from "dayjs/plugin/localizedFormat"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import { AppContext, AppProps } from "next/app"
import Head from "next/head"
import { RecoilRoot } from "recoil"
import { Provider as StyletronProvider } from "styletron-react"
import { SWRConfig } from "swr"
import { SideNavigation, TopNavigation } from "../components"
import {
  authConfig,
  INVOICE_BASE_URL,
  OPENAPI_BASE_URL,
  TAKER_BASE_URL,
  TRACER_BASE_URL,
  TRACKER_BASE_URL,
} from "../config"
import locale from "../locale"
import "../styles/globals.css"
import { styletron } from "../styletron"
import { MapProvider } from "../utils/map"
import { AuthTimerProvider } from "@today/auth/components/timer"

// dayjs 포맷팅 한국어로 설정
dayjs.locale("ko")
dayjs.extend(isoWeek)
dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

function App({
  Component,
  pageProps,
  authInitialProp,
}: AppProps & AuthInitialProps) {
  return (
    <>
      <Head>
        <title>Today Portal</title>
        <meta lang="ko" />
        <meta charSet="utf-8" />
        <meta name="google" content="notranslate" />
      </Head>
      <RecoilRoot>
        <AuthenticationProvider
          {...authConfig}
          authInitialProp={authInitialProp}
        >
          <APIProvider
            takerBaseUrl={TAKER_BASE_URL}
            trackerBaseUrl={TRACKER_BASE_URL}
            tracerBaseUrl={TRACER_BASE_URL}
            invoiceBaseUrl={INVOICE_BASE_URL}
            openApiBaseUrl={OPENAPI_BASE_URL}
          >
            <SWRConfig
              value={{
                fetcher: (url) => axios.get(url).then((res) => res.data),
              }}
            >
              <StyletronProvider value={styletron}>
                <BaseProvider theme={LightTheme}>
                  <LocaleProvider locale={locale}>
                    <ToasterContainer
                      autoHideDuration={3000}
                      placement="bottomLeft"
                    >
                      <AuthTimerProvider seconds={15 * 60}>
                        <MapProvider>
                          <div
                            className="flex h-screen flex-col"
                            style={{ height: "100dvh" }}
                          >
                            <TopNavigation />
                            <div className="flex flex-1 overflow-hidden">
                              <SideNavigation />
                              <div className="flex-1 overflow-hidden">
                                <Component {...pageProps} />
                              </div>
                            </div>
                          </div>
                        </MapProvider>
                      </AuthTimerProvider>
                    </ToasterContainer>
                  </LocaleProvider>
                </BaseProvider>
              </StyletronProvider>
            </SWRConfig>
          </APIProvider>
        </AuthenticationProvider>
      </RecoilRoot>
    </>
  )
}

App.getInitialProps = withAuth(async (context: AppContext) => ({}))

export default App
