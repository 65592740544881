import { Button } from "baseui/button"
import { useAuthTimer } from "./AuthTimerProvider"

export function AuthTimerDisplay() {
  const { remainingSeconds, touch } = useAuthTimer()
  const min = Math.floor(remainingSeconds / 60)
  const sec = remainingSeconds - min * 60
  return (
    <div className="text-center text-xs">
      {remainingSeconds <= 0 ? (
        <span className="font-bold text-red-500">00:00</span>
      ) : (
        <span
          className={remainingSeconds <= 180 ? "font-bold text-red-500" : ""}
        >
          {min.toString().padStart(2, "0")}:{sec.toString().padStart(2, "0")}
        </span>
      )}
      <Button
        disabled={remainingSeconds <= 0}
        onClick={() => touch()}
        size="mini"
        className="ml-2 inline-block px-1 py-0.5"
        kind="secondary"
      >
        로그인 연장
      </Button>
    </div>
  )
}
